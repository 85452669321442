<template>
	<div class="revenues-monthly">
	<!-- Chart -->
	<mdb-line-chart
		v-if="render"
		:data="lineChartDataProp"
		:options="($store.state.theme === 'theme-light')?lineChartOptions:lineChartDarkOptions"
		:height="320"
	></mdb-line-chart>
	</div>
</template>

<script>
import globalConfig from '@/tstba.conf'
import {
mdbLineChart,
} from "mdbvue";
export default {
name:'revenues-monthly',
components: {
	mdbLineChart,
},

props:{
	lineChartDataProp:{
		type:Object
	},
	render:{
		type:Boolean,
		default:false
	}
},

data(){

	return{
		revenues:[],
		chartRender:false,
		lineChartData: {
			labels: [],
			datasets: [
			{
				label: "Facture de vente",
				backgroundColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fv.bgcolor : globalConfig.theme_interface.chart.dark.fv.bgcolor,
				borderColor:(this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fv.borderColor : globalConfig.theme_interface.chart.dark.fv.borderColor,
				pointBackgroundColor: "transparent",
				borderWidth: 2,
				data: []
			},
			{
				label: "Facture d'avoir",
				backgroundColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fa.bgcolor : globalConfig.theme_interface.chart.dark.fa.bgcolor,
				borderColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fa.borderColor : globalConfig.theme_interface.chart.dark.fa.borderColor,
				pointBackgroundColor: "transparent",
				borderWidth: 2,
				data: []
			}
			]
		},
		lineChartOptions: {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				xAxes: [
					{
						gridLines: {
							display: true,
							color: "rgba(0, 0, 0, 0.1)"
						},
						ticks: {
							fontColor: "black" 
						}
					}
				],
				yAxes: [
					{
						gridLines: {
							display: true,
							color: "rgba(0, 0, 0, 0.1)"
						},
						ticks: {
							fontColor: "black" 
						}
					}
				]
			},
			legend: {
				labels: {
					fontColor: "black" 
				}
			}
		},
		lineChartDarkOptions: {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				xAxes: [
					{
						gridLines: {
							display: true,
							color: "rgba(0, 0, 0, 0.1)"
						},
						ticks: {
							fontColor:  "white"
						}
					}
				],
				yAxes: [
					{
						gridLines: {
							display: true,
							color: "rgba(0, 0, 0, 0.1)"
						},
						ticks: {
							fontColor:  "white"
						}
					}
				]
			},
			legend: {
				labels: {
					fontColor:  "white"
				}
			}
		},
	}
},

methods:{
	async getRevenues(){
		this.$nprogress.start()
		await this.$store.dispatch('report/revenuesByMonthReports')
		.then((response)=>{
			this.lineChartData.labels = [];
			this.lineChartData.datasets[0].data = []
			this.lineChartData.datasets[1].data = []
			// this.$store.commit('toggleChartRender',false)
			this.chartRender = false
			this.lineChartData.labels = Object.keys(response.data.revenues.fv)
			this.lineChartData.datasets[0].data = Object.values(response.data.revenues.fv)
			this.lineChartData.datasets[1].data = Object.values(response.data.revenues.fa)
			this.$nprogress.done()
			this.$nextTick(()=>{
				// this.$store.commit('toggleChartRender',true)
				this.chartRender = true
			})
		})
		.catch((error)=>{
			this.$nprogress.done()
			this.$notify({
				message: error.response.data.message  || error.response.data.error,
				type: 'danger'
			})
		});
	}
},

}
</script>
