<template>
  <div class="revenues">
    <!--Section: Button icon-->
    <section v-if="!$isMobile()" >
      <!--Grid row-->
      <mdb-row>
        <!--Grid column-->
        <mdb-col xl="3" md="6" class="mb-4" v-for="(item, index) in revenueProp" :key="index">
          <!--Card-->
          <mdb-card  class="card-reversed">
            <!--Card Data-->
            <mdb-row class="mt-3">
              <mdb-col md="2" col="2" class=" text-left pl-4">
                <mdb-btn tag="a" floating size="md" color="primary" class="ml-4"
                  ><mdb-icon icon="file-invoice-dollar" aria-hidden="true"
                /></mdb-btn>
              </mdb-col>

              <mdb-col md="10" col="10" class=" text-right pr-5" >
                <h5 class=" mt-3 mb-2 font-weight-bold">{{item.totalWithUid | smartNumber}}</h5>
                
                <p class="color-normal-reversed" style="font-size:0.7rem!important;"> 
                    <span style="">  Chiffre d'affaire  </span>
                    <span > {{item.ref.toUpperCase()}} </span>
                    <br>
                    <b  >{{item.type.slice(10).toUpperCase()}}</b>
                </p>
              </mdb-col>
            </mdb-row>
            <!--/.Card Data-->

            <!--Card content-->
            <mdb-row class="my-1">
              <mdb-col md="12" col="12" class=" text-left pl-4 pr-5">
                <div class="d-flex pb-2 justify-content-between item-align-center">
                  <div
                    class="color-normal-reversed font-up ml-4 font-weight-bold"
                    style="font-size:0.8rem"
                  >
                   <span v-if="option === 'Ce Mois'">les 30 derniers jours</span>  
                   <span v-if="option === '3 Mois'"> les 3 derniers nois </span>  
                   <span v-if="option === '6 Mois'">les 6 derniers mois</span>  
                   <span v-if="option === 'Cette Année'">les 12 derniers mois</span>  
                   <span v-if="option === 'Cette Semaine'">les 7 derniers jours</span>  
                  </div>
                  <div style="font-size:0.8rem" class=" color-normal-reversed">{{item.totalWithoutUid | smartNumber}}</div>

                </div>
              </mdb-col>
            </mdb-row>
            <!--/.Card content-->
          </mdb-card>
          <!--/.Card-->
        </mdb-col>
        <!--Grid column-->
      </mdb-row>
      <!--Grid row-->
    </section>
    <section v-if="$isMobile()" class="mb-4" >
      <mdb-row>
        <mdb-col col="6" class="mb-2" v-for="(item, index) in revenueProp" :key="index">
          <mdb-card>
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3" >
                <div style="font-size:0.7rem!important;"> 
                  <h5 class="font-weight-bold">{{item.totalWithUid | smartNumber }}</h5>
                  <b>{{item.type.slice(10).toUpperCase()}}</b>
                  <div style="font-size:0.8rem" class=" color-normal-reversed">{{item.totalWithoutUid | smartNumber}}</div>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <!--/Section: Button icon-->
  </div>
</template>

<script>
import {
  mdbIcon,
  mdbBtn,
  mdbRow,
  mdbCol,
  mdbCard,
} from "mdbvue";

export default {
  name: 'revenues',
  components: {
    mdbIcon,
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbCard,
  },

  props:['revenueProp','option'],

  data() {
      return {
          revenues : []
      }
  },

    methods:{
        async getRevenues(){
            this.$nprogress.start()
            await this.$store.dispatch('report/revenuesReports')
            .then((response)=>{
                this.$nprogress.done()
                this.revenues = response.data.revenues;
            })
            .catch((error)=>{
                this.$nprogress.done()
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            });
        }
    },
    created(){ 
    }
}
</script>