<template>
  <div class="users">
    <section class="section">
        <mdb-card class="mb-4 card-cascade">
          <div
              class="card-header-bg view view-cascade light-teps gradient-card-header  color-primary-reversed card-header-reversed"
            >
              <h6 class="mb-0 " style="font-weight: 500;">Utilisateurs</h6>
            </div>
          <mdb-card-body  class="card-reversed color-normal-reversed">
            <div class="table-responsive">
              <table class="table large-header">
                <tbody>
                  <tr>
                    <th class=" color-normal-reversed">Total Utilisateurs Activés</th>
                    <td class="color-normal-reversed"><span>{{userIsActive | formatNumber}} </span></td>
                  </tr>
                  <tr>
                    <th class=" color-normal-reversed">Total Total Utilisateurs Désactivés</th>
                    <td class="color-normal-reversed"><span>{{userIsNotActive | formatNumber}} </span></td>
                  </tr>
                  <tr>
                    <th class=" color-normal-reversed">Total Utilisateurs Archivés</th>
                    <td class="color-normal-reversed"><span>{{userIsArchived | formatNumber}} </span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mdb-card-body>
        </mdb-card>
    </section>
  </div>
</template>

<script>
import {
  mdbCard,
    mdbCardBody
} from "mdbvue";
export default {
  name: 'users',
  components: {
    mdbCard,
    mdbCardBody
  },
  data(){
    return{
      userIsActive:0,
      userIsNotActive:0,
      userIsArchived:0,
      userIsNotArchived:0,
    }
  },

  methods:{
    async getUsers(){
      this.$nprogress.start()
      await this.$store.dispatch('report/usersReports')
      .then((response)=>{
          this.$nprogress.done()
          this.userIsActive = response.data.userIsActive;
          this.userIsNotActive = response.data.userIsNotActive;
          this.userIsArchived = response.data.userIsArchived;
          this.userIsNotArchived = response.data.userIsNotArchived;
      })
      .catch((error)=>{
          this.$nprogress.done()
          this.$notify({
              message: error.response.data.message  || error.response.data.error,
              type: 'danger'
          })
      });
    }
  },
  created(){
    this.getUsers()
  }
}
</script>
